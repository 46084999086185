export default {
    data() {
        return {
            dataTable: null,
            searchText: ''
        };
    },
    watch: {
        searchText() {
            this.searchTable();
        }
    },
    methods: {
        createDataTable(tableId) {
            if (this.dataTable) this.dataTable.destroy();

            let columnDefs = {};
            let order = [[0, 'desc']];

            if (tableId === '#products-table') {
                columnDefs = [{ targets: [2], searchable: false }];
                order = [[0, 'asc']];
            } else if (tableId === '#product-items-table') {
                columnDefs = [{ targets: [1, -1], searchable: false }];
            } else if (tableId === '#shipments-table') {
                columnDefs = [{
                    targets: [0],
                    searchable: false,
                    visible: false
                }];
                order = [[0, 'desc']];
            }

            this.$nextTick(() => {
                this.dataTable = $(tableId).DataTable({
                    dom: 'tp',
                    ordering: true,
                    displayLength: 10,
                    language: {
                        zeroRecords: 'No information to display',
                        paginate: {
                            previous: 'Prev'
                        }
                    },
                    columnDefs,
                    order
                });
                this.dataTable.draw();

                this.searchTable();
            });
        },
        searchTable() {
            if (this.dataTable) {
                this.dataTable.search(this.searchText).draw();
                if (this.dataTable.page.info().recordsDisplay === 0) {
                    $('thead').hide();
                    $('ul.pagination').hide();
                } else {
                    $('thead').show();
                    $('ul.pagination').show();
                }
            }
        }
    }
};
