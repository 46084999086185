<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header d-flex align-items-center">
                <div>Shipments</div>
                <div class="form-group ml-auto mb-0">
                    <input type="text"
                           class="form-control form-control-sm my-auto"
                           v-model="searchText"
                           placeholder="Search"/>
                </div>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <span v-if="!shipments.length && !loading">No information to display</span>
                <div v-if="shipments.length && !loading">
                    <table class="table table-sm table-borderless" id="shipments-table">
                        <thead>
                            <tr>
                                <th>Shipment ID</th>
                                <th>BC ID</th>
                                <th>Ship date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-row"
                                v-for="shipment in shipments"
                                :key="shipment.id"
                                @click="routeToShipment(shipment.id)">
                                <td>{{ shipment.id }}</td>
                                <td>{{ shipment.order ? shipment.order.big_commerce_order_id : 'N/A' }}</td>
                                <td>{{ formatDate(shipment.created_at) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getShipments } from '../../services/ShipmentService';
import dataTableMixin from '../../mixins/dataTable';
import Spinner from 'vue-simple-spinner';
import ViewOrderButton from '../../components/shared/ViewOrderButton';

export default {
    name: 'Shipments',
    components: {
        Spinner,
        ViewOrderButton
    },
    mixins: [dataTableMixin],
    props: {
        bc_order_id: Number
    },
    data() {
        return {
            shipments: [],
            loading: false,
            searchText: ''
        };
    },
    mounted() {
        this.searchText = this.bc_order_id || '';
        this.getShipments();
    },
    methods: {
        async getShipments() {
            try {
                this.loading = true;
                const response = await getShipments();
                this.shipments = response.data;
                this.createDataTable('#shipments-table');
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        formatDate(date) {
            return moment(date).format('ll');
        },
        routeToShipment(id) {
            this.$router.push({ name: 'shipment-details', params: { id } });
        }
    }
};
</script>

