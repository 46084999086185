<template>
    <button
        @click="routeToOrder(orderId)"
        class="text-center see-order-btn btn btn-sm btn-light"
        type="button"
        :disabled="loadingOrder"
    >
        <span v-if="loadingOrder" class="spinner-border spinner-border-sm mr-2" role="status"></span>See order
    </button>
</template>

<script>
import { getOrder } from '../../services/OrderService';

export default {
    name: 'ViewOrderButton',
    props: {
        orderId: Number
    },
    data() {
        return {
            loadingOrder: false
        };
    },
    methods: {
        async routeToOrder(orderId) {
            try {
                this.loadingOrder = true;
                const response = await getOrder(orderId);
                if ([3, 11].includes(response.data.status.id)) {
                    this.$router.push({ name: 'order-fulfillment', params: { id: orderId } })
                } else {
                    this.$router.push({
                        name: 'shipments-list',
                        params: { bc_order_id: response.data.big_commerce_order_id }
                    })
                }
            } catch (error) {
                console.log(error);
            }
            this.loadingOrder = false;
        }
    }
};
</script>