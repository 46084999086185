import axios from 'axios';

export const getProducts = () => {
    return axios.get('products/');
}

export const getProduct = id => {
    return axios.get(`products/${id}/`);
}

export const getProductItems = id => {
    return axios.get(`products/${id}/items/`);
}

export const addProduct = product => {
    return axios.post('products/', product);
}

export const updateProduct = product => {
    return axios.put(`products/${product.id}/`, product);
}

export const deleteProduct = id => {
    return axios.delete(`products/${id}/`);
}

export const printLabels = (amount, id) => {
    return axios.create({
        timeout: amount * 1000 + 10000,
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: { Authorization: `Token ${localStorage.getItem('token')}` }
    }).post(`products/${id}/labels/`, { amount });
}
