import axios from 'axios';
import { getProducts } from '../services/ProductService';
import { DEFAULT_IMAGE } from '../constants';

export const getOrders = status => {
    return axios.get(`orders/?status=${status}`);
};

export const getOrder = id => {
    return axios.get(`orders/${id}/`);
};

export const getOrderProducts = async (order, shipments) => {
    const response = await getProducts();
    const ourProducts = response.data;
    const orderProducts = order.bc_data.products.map(bcProduct => {
        const product = ourProducts.find(p => p.big_commerce_id === bcProduct.product_id);
        return product
            ? {
                  name: product.name,
                  image: product.image_url || DEFAULT_IMAGE,
                  id: product.id,
                  bigCommerceId: product.big_commerce_id,
                  qtyFulfilled: 0,
                  qtyShippedIn: product.shipped_in_quantity,
                  qtyInStock: product.quantity,
                  qtyToShip: product.shipped_in_quantity * bcProduct.quantity,
                  missing: false
              }
            : {
                  name: bcProduct.name,
                  image: DEFAULT_IMAGE,
                  bigCommerceId: bcProduct.product_id,
                  qtyInStock: 0,
                  qtyFulfilled: 0,
                  qtyToShip: bcProduct.quantity,
                  missing: true
              };
    });

    // Update quantity fulfilled for order products
    shipments.forEach(shipment => {
        shipment.items.forEach(item => {
            const product = orderProducts.find(p => p.bigCommerceId === item.big_commerce_id);
            if (product && !item.shipped_as_extra) {
                product.qtyFulfilled++;
            }
        });
    });

    return orderProducts;
};

export const getOrderShipments = id => {
    return axios.get(`orders/${id}/shipments/`);
};

export const updateOrder = (id, status_id) => {
    return axios.patch(`orders/${id}/`, { status: status_id });
}
